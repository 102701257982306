<template>
  <section class="login">
  </section>
</template>

<script>
export default {
  mounted() {
    this.loginByToken()
  },
  methods: {
    async loginByToken() {
      await this.$store
        .dispatch("auth/loginByToken", { token: this.$route.query.token, org_code: this.$route.params.org_code })
        .then(() => {
          this.$router.push({
            name: "DashboardJump",
            params: { org_code: this.$route.params.org_code },
          });
        })
        .catch((error) => {
          console.error(error);
          this.$swal("失敗", error.response.data.message, "error").then(() => {
            this.$router.push({ name: 'AuthLogin' })
          });
        });
    },
  },
};
</script>
